import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import News from "../components/News";
import NavThree from "../components/NavThree";
export const query = graphql`
    query($limit:Int!, $skip:Int!, $categorySlug:String!){
        allStrapiArticles(skip: $skip, sort: {fields: updated_at, order: DESC}, limit: $limit, filter: {categories: {elemMatch: {slug: {eq: $categorySlug}}}}) {
            edges {
                node {
                    id
                    slug
                    title
                    shortdescription
                    image {
                        localFile{
                            sharp: childImageSharp {
                                fluid( maxWidth: 307, maxHeight: 307, quality: 100) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
            pageInfo {
                currentPage
                itemCount
                pageCount
                perPage
            }
        }
    }
`

const CategoryList = ({data}) => {
    return (
        <Layout pageTitle="Articulos | Oshytech">
            <NavThree/>
            <PageHeader title="Blog"/>
            <News
                listData={data.allStrapiArticles.edges.map(it => it.node)}
                pageInfo={data.allStrapiArticles.pageInfo}/>
            <Footer/>
        </Layout>
    );
};

export default CategoryList;
